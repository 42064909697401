<template>
  <v-dialog
    @click:outside="cancel"
    v-model="dialog"
    :width="options.width"
  >
    <slot />
  </v-dialog>

</template>
<script>
export default {
  data () {
    return {
      dialog: false,
      askDuplicates: false,
      options: {
        width: '30vw'
      },
      resolve: null
    }
  },
  methods: {
    open (options) {
      this.dialog = true
      this.options = Object.assign({}, this.options, options)
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    agree () {
      this.resolve(true)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    },
    agreed () {
      this.resolve(true)
      this.dialog = false
    }
  },
  provide: function () {
    return { agree: this.agree, cancel: this.cancel, agreed: this.agreed }
  }
}
</script>
